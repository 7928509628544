import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  nodes: [
    {
      id: "1",
      type: "mindmap",
      data: { label: "Root Node" },
      position: { x: 0, y: 0 },
    },
  ],
  edges: [],
};

const mindMapSlice = createSlice({
  name: "mindMap",
  initialState,
  reducers: {
    updateNodeLabel: (state, action) => {
      const { id, label } = action.payload;
      const node = state.nodes.find((n) => n.id === id);
      if (node) {
        node.data.label = label;
      }
    },
    addChildNode: (state, action) => {
      const { parentNode, newNodePosition } = action.payload;
      const newNode = {
        id: `${state.nodes.length + 1}`,
        type: "mindmap",
        data: { label: "New Node" },
        position: newNodePosition,
      };
      const newEdge = {
        id: `${state.edges.length + 1}`,
        source: parentNode.id,
        target: newNode.id,
      };
      state.nodes.push(newNode);
      state.edges.push(newEdge);
    },
  },
});

export const { updateNodeLabel, addChildNode } = mindMapSlice.actions;
export default mindMapSlice.reducer;
