import React from "react";
import {
  ReactFlow,
  ReactFlowProvider,
  Handle,
  Position,
  MiniMap,
  Controls,
  Background,
  Panel,
  useNodesState,
  useEdgesState,
  addEdge,
} from "@xyflow/react";
import "@xyflow/react/dist/style.css";
import "./styles.css"; // Подключение CSS файла
import Button from "@mui/material/Button";
import SaveIcon from "@mui/icons-material/Save";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { Box, TextField, IconButton } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

// Кастомный узел с соединениями со всех сторон
const CustomNode = ({ id, data, selected }) => {
  return (
    <div
      style={{
        padding: "10px",
        border: "1px solid #ccc",
        borderRadius: "5px",
        background: selected ? "#ffcc00" : "#5496e1", // Изменяем цвет фона при выделении
        color: selected ? "#000" : "#fff",
        fontSize: "14px",
        padding: "5px 17px",
        position: "relative",
      }}
    >
      {/* Handle для источника (source) */}
      <Handle type="source" position={Position.Top} id={`${id}-top-source`} />
      <Handle
        type="source"
        position={Position.Right}
        id={`${id}-right-source`}
      />
      <Handle
        type="source"
        position={Position.Bottom}
        id={`${id}-bottom-source`}
      />
      <Handle type="source" position={Position.Left} id={`${id}-left-source`} />

      {/* Handle для цели (target) */}
      <Handle type="target" position={Position.Top} id={`${id}-top-target`} />
      <Handle
        type="target"
        position={Position.Right}
        id={`${id}-right-target`}
      />
      <Handle
        type="target"
        position={Position.Bottom}
        id={`${id}-bottom-target`}
      />
      <Handle type="target" position={Position.Left} id={`${id}-left-target`} />

      <div>{data.label}</div>
    </div>
  );
};

const nodeTypes = {
  custom: CustomNode, // Регистрируем кастомный тип узла
};

const initialNodes = [
  {
    id: "1",
    type: "custom", // Используем кастомный тип узла
    data: { label: "Центральный раздел" },
    position: { x: 400, y: 100 },
  },
];
const initialEdges = [];

const MindMap = () => {
  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);
  const [nodeId, setNodeId] = React.useState(2);
  const [selectedElements, setSelectedElements] = React.useState({
    nodes: [],
    edges: [],
  });
  const [clipboard, setClipboard] = React.useState({ nodes: [], edges: [] });
  const prevSelectedElements = React.useRef(selectedElements);
  const [editNode, setEditNode] = React.useState(null);
  const [newLabel, setNewLabel] = React.useState("");
  const [editPosition, setEditPosition] = React.useState({ top: 0, left: 0 });
  const [hiddenNodes, setHiddenNodes] = React.useState(new Set());
  const [isAddPage, setIsAddPage] = React.useState(true);
  const [pageText, setPageText] = React.useState("");

  const onConnect = (params) => {
    console.log("Connecting nodes:", params);
    setEdges((eds) => addEdge(params, eds));
  };

  const addNode = () => {
    const newNode = {
      id: `${nodeId}`,
      type: "custom", // Используем кастомный тип узла
      data: { label: `Раздел ${nodeId}` },
      position: { x: Math.random() * 300, y: Math.random() * 100 },
    };
    console.log("Adding new node:", newNode);
    setNodes((nds) => nds.concat(newNode));
    setNodeId(nodeId + 1);
  };

  const handleAddPages = () => {
    const pages = pageText.split("\n").filter((page) => page.trim() !== "");
    const newNodes = pages.map((page, index) => ({
      id: `${nodeId + index}`,
      type: "custom",
      data: { label: page },
      position: { x: Math.random() * 400, y: Math.random() * 100 },
    }));
    setNodes((nds) => nds.concat(newNodes));
    setNodeId(nodeId + newNodes.length);
    setPageText(""); // Очищаем поле ввода после добавления
  };

  const handleSelectionChange = (elements) => {
    console.log("Selected elements:", elements);
    if (
      JSON.stringify(elements) !== JSON.stringify(prevSelectedElements.current)
    ) {
      setSelectedElements(elements);
      prevSelectedElements.current = elements;
    }
  };

  const handleCopy = () => {
    console.log("Copying elements:", selectedElements);
    if (selectedElements.nodes && selectedElements.nodes.length > 0) {
      setClipboard(selectedElements);
    }
  };

  const handlePaste = () => {
    console.log("Pasting elements from clipboard:", clipboard);
    if (clipboard.nodes && clipboard.nodes.length > 0) {
      const newNodes = clipboard.nodes.map((node, index) => ({
        ...node,
        id: `${nodeId + index}`,
        position: {
          x: node.position.x + 50, // Смещаем новые узлы для видимости
          y: node.position.y + 50,
        },
        selected: false, // Снимаем выделение с новых узлов
      }));
      setNodes((nds) => nds.concat(newNodes));
      setNodeId(nodeId + newNodes.length);
    }
  };

  const handleDelete = () => {
    console.log("Deleting selected elements:", selectedElements);
    if (selectedElements.nodes && selectedElements.nodes.length > 0) {
      const selectedNodeIds = selectedElements.nodes.map((node) => node.id);
      console.log("Selected node IDs to delete:", selectedNodeIds);
      setNodes((nds) => {
        const updatedNodes = nds.filter(
          (node) => !selectedNodeIds.includes(node.id)
        );
        console.log("Updated nodes after deletion:", updatedNodes);
        return updatedNodes;
      });
      setSelectedElements({ nodes: [], edges: [] }); // Сбрасываем выделение
    }
  };

  const handleKeyDown = (event) => {
    console.log("Key down event:", event);
    if (event.ctrlKey && event.keyCode === 67) {
      // 67 is the keyCode for 'c'
      event.preventDefault();
      console.log("Ctrl + C pressed");
      handleCopy();
    } else if (event.ctrlKey && event.keyCode === 86) {
      // 86 is the keyCode for 'v'
      event.preventDefault();
      console.log("Ctrl + V pressed");
      handlePaste();
    }
  };

  React.useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleKeyDown]);

  React.useEffect(() => {
    const handleKeyDown = (event) => {
      console.log("Key down event:", event);
      if (event.ctrlKey || event.metaKey) {
        if (event.key === "c") {
          event.preventDefault();
          handleCopy();
        } else if (event.key === "v") {
          event.preventDefault();
          handlePaste();
        }
      } else if (event.key === "Delete") {
        event.preventDefault();
        handleDelete();
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleCopy, handlePaste, handleDelete]);

  React.useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleKeyDown]);

  const handleDoubleClick = (event, node) => {
    event.stopPropagation();
    setEditNode(node);
    setNewLabel(node.data.label || "");
    const bounds = document
      .querySelector(`[data-id="${node.id}"]`)
      ?.getBoundingClientRect();
    if (bounds) {
      setEditPosition({
        top: bounds.top - 30, // Adjust position as needed
        left: bounds.left + bounds.width / 2 - 75, // Center the input horizontally
      });
    }
  };

  const handleChange = (event) => {
    setNewLabel(event.target.value);
  };

  const handleClose = (event) => {
    event.stopPropagation();
    if (editNode) {
      setNodes((nds) =>
        nds.map((nd) =>
          nd.id === editNode.id ? { ...nd, data: { label: newLabel } } : nd
        )
      );
    }
    setEditNode(null);
  };

  const handleClickOutside = (event) => {
    if (editNode && !event.target.closest(".edit-node")) {
      handleClose(event);
    }
  };

  const handleSaveClick = (event) => {
    event.stopPropagation();
    handleClose(event);
  };

  const getConnectedNodes = (nodeId) => {
    const connectedNodeIds = new Set();
    edges.forEach((edge) => {
      if (edge.source === nodeId) {
        connectedNodeIds.add(edge.target);
      } else if (edge.target === nodeId) {
        connectedNodeIds.add(edge.source);
      }
    });
    console.log(
      "Connected nodes for node",
      nodeId,
      "are:",
      Array.from(connectedNodeIds)
    );
    return connectedNodeIds;
  };

  const toggleVisibility = (nodeId) => {
    const connectedNodes = getConnectedNodes(nodeId);
    let newHiddenNodes;
    if (hiddenNodes.has(nodeId)) {
      newHiddenNodes = new Set(hiddenNodes);
      connectedNodes.forEach((id) => newHiddenNodes.delete(id));
      newHiddenNodes.delete(nodeId);
    } else {
      newHiddenNodes = new Set(hiddenNodes);
      connectedNodes.forEach((id) => newHiddenNodes.add(id));
      newHiddenNodes.add(nodeId);
    }
    console.log(
      "Toggling visibility for node",
      nodeId,
      "new hidden nodes:",
      Array.from(newHiddenNodes)
    );
    setHiddenNodes(newHiddenNodes);
  };

  React.useEffect(() => {
    if (editNode) {
      document.addEventListener("click", handleClickOutside);
    } else {
      document.removeEventListener("click", handleClickOutside);
    }
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [editNode]);

  const handleDisconnect = (nodeId) => {
    console.log("Disconnecting node:", nodeId);
    setEdges((eds) =>
      eds.filter((edge) => edge.source !== nodeId && edge.target !== nodeId)
    );
  };

  return (
    <ReactFlowProvider>
      <div style={{ height: "95vh" }}>
        <ReactFlow
          nodes={nodes}
          edges={edges}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          onConnect={onConnect}
          onSelectionChange={handleSelectionChange}
          deleteKeyCode={46}
          onNodeDoubleClick={(e, node) => handleDoubleClick(e, node)}
          nodeTypes={nodeTypes}
          nodesDraggable={true}
          nodesConnectable={true}
          elementsSelectable={true}
        >
          <Panel position="top-left">
            <Box sx={{ display: "flex", gap: "30px" }}>
              <Button
                size="small"
                variant="outlined"
                onClick={handleCopy}
                style={{ background: "#fff" }}
              >
                Скопировать
                <br /> ctrl + c
              </Button>
              <Button
                size="small"
                variant="outlined"
                onClick={handlePaste}
                style={{ background: "#fff" }}
              >
                Вставить
                <br /> ctrl + v
              </Button>
            </Box>
          </Panel>
          <Panel position="top-center">
            <Box sx={{ display: "flex", gap: "30px" }}>
              <Button
                size="small"
                variant="outlined"
                onClick={addNode}
                style={{ background: "#fff" }}
              >
                Добавить
              </Button>
              <Button
                size="small"
                variant="outlined"
                onClick={handleDelete}
                style={{ background: "#fff" }}
              >
                Удалить
                <br /> del
              </Button>
              <Button
                size="small"
                variant="outlined"
                style={{ background: "#fff" }}
                onClick={() => handleDisconnect(selectedElements.nodes[0]?.id)}
              >
                Отсоединить <br /> узел
              </Button>
            </Box>
          </Panel>

          <Panel position="bottom-left">
            <Controls />
          </Panel>
          <Panel position="bottom-right">
            <MiniMap />
          </Panel>
          <Panel position="top-right">
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              {isAddPage && (
                <Box sx={{ position: "relative" }}>
                  <TextField
                    id="page-map"
                    label="Добавить в структуру страницы"
                    placeholder="Каждая страница с новой строки"
                    minRows={10}
                    maxRows={20}
                    multiline
                    sx={{ background: "#fff", width: "100%" }}
                    value={pageText}
                    onChange={(e) => setPageText(e.target.value)}
                  />
                  <IconButton
                    onClick={handleAddPages}
                    sx={{
                      position: "absolute",
                      right: 8,
                      bottom: 8,
                      color: "green",
                    }}
                  >
                    <AddIcon />
                  </IconButton>
                </Box>
              )}

              <Button
                size="small"
                sx={{ background: "#fff" }}
                variant="outlined"
                onClick={(e) => setIsAddPage(!isAddPage)}
              >
                {isAddPage ? "Спрятать" : "Добавить страницы"}
              </Button>
            </Box>
          </Panel>
          <Background />
        </ReactFlow>
        {editNode && (
          <div
            className="edit-node"
            style={{
              top: editPosition.top,
              left: editPosition.left,
            }}
          >
            <input
              type="text"
              value={newLabel}
              onChange={handleChange}
              autoFocus
            />
            <Button
              variant="contained"
              color="primary"
              size="small"
              startIcon={<SaveIcon />}
              onClick={handleSaveClick}
              style={{ marginLeft: "5px" }}
            >
              Сохранить
            </Button>
          </div>
        )}
        {selectedElements.length > 0 && (
          <Button
            variant="contained"
            color="primary"
            size="small"
            startIcon={
              hiddenNodes.has(selectedElements[0].id) ? (
                <VisibilityIcon />
              ) : (
                <VisibilityOffIcon />
              )
            }
            onClick={() => toggleVisibility(selectedElements[0].id)}
            style={{ marginTop: "10px" }}
          >
            {hiddenNodes.has(selectedElements[0]?.id) ? "Показать" : "Скрыть"}
          </Button>
        )}
      </div>
    </ReactFlowProvider>
  );
};

export default MindMap;
