import React, { useState } from "react";
import Header from "./Header";
import { connect } from "react-redux";
import HeaderNoAuth from "./HeaderNoAuth";
import AssessmentIcon from "@mui/icons-material/Assessment";
import CreditCardIcon from "@mui/icons-material/CreditCard";

function HeaderContainer(props) {
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [open, setOpen] = React.useState(false);

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  const pages = [
    {
      name: "Баланс",
      link: "/balance/",
      icon: <CreditCardIcon ontSize="small" />,
    },
    {
      name: "Кабинет",
      link: "/cabinet/",
      icon: <AssessmentIcon ontSize="small" />,
    },
  ];

  return (
    <>
      {props.isAuthenticated ? (
        <Header
          money={props.money}
          lvt={props.lvt}
          bonusDayLvt={props.bonusDayLvt}
          lvtPresent={props.lvtPresentRegistration + props.lvtPresentReferal}
          notifications={props.notifications}
          isAuthenticated={props.isAuthenticated}
          totalLvt={props.totalLvt}
          pages={pages}
          anchorElUser={anchorElUser}
          toggleDrawer={toggleDrawer}
          anchorElNav={anchorElNav}
          open={open}
        />
      ) : (
        <HeaderNoAuth
          anchorElNav={anchorElNav}
          open={open}
          setOpen={setOpen}
          setAnchorElNav={setAnchorElNav}
          pages={pages}
        />
      )}
    </>
  );
}

let mapStateToProps = (state) => {
  return {
    money: state.user.dataUser.money,
    lvt: state.user.dataUser.lvt,
    bonusDayLvt: state.user.dataUser.bonusDayLvt,
    lvtPresentReferal: state.user.dataUser.lvtPresent.lvtPresentReferal,
    lvtPresentRegistration:
      state.user.dataUser.lvtPresent.lvtPresentRegistration,
    notifications: state.user.dataUser.notifications,
    isAuthenticated: state.user.isAuthenticated,
    totalLvt: state.user.dataUser.totalLvt,
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(HeaderContainer);
