import { Box, Button, Paper, Typography } from "@mui/material";
import React from "react";

export default function BlockDomenVariant(props) {
  const { functionClick, name, description, textButton, zonaDomen } = props;
  return (
    <Paper elevation={3}>
      <Box p={2}>
        <Typography component="h2" variant="h5" gutterBottom>
          {name}
        </Typography>
        <Typography gutterBottom variant="body1">
          {description}
        </Typography>
        <Button
          variant="contained"
          sx={{ backgroundColor: "#4CAF50" }}
          onClick={() => functionClick(zonaDomen)}
        >
          {textButton}
        </Button>
      </Box>
    </Paper>
  );
}
