import React, { useEffect, useRef, useState } from "react";
import CountWordKey from "./CountWordKey";
import {
  getCountWord,
  getDirectBall,
  loadFileUserTools,
} from "../Api/api-tools";
import { connect } from "react-redux";
import { spendLvt } from "../Api/api-lvt";
import Papa from "papaparse";
import { TitleComponent } from "../Function/TitleComponent";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useSnackbar } from "notistack"; // Импортируем SnackbarProvider и useSnackbar
import * as XLSX from "xlsx";

function CountWordKeyContainer({
  getCountWord,
  getDirectBall,
  loadFileUserTools,
  spendLvt,
  userId,
  isAuthenticated,
  totalLvt,
  toolsSidebar,
  tools,
  ballDirect,
}) {
  const [queryArray, setQueryArray] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [result, setResult] = useState([]);
  const [csvDownloadLink, setCsvDownloadLink] = useState("");
  const [lvtUserSpend, setLvtUserSpend] = useState(0);
  const [sumKeyLvt, setSumKeyLvt] = useState(0);
  const [region, setSelectedCity] = useState(225);
  const [sumLvt, setSumLvt] = useState(0);
  const [noLvtUser, setNoLvtUserFetching] = useState(false);
  const [exceedsBallDirect, setExceedsBallDirect] = useState(false);

  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("lg"));
  const { enqueueSnackbar } = useSnackbar(); // Получаем функцию enqueueSnackbar

  const tarif = 0.1;
  const frontTarif = "10 баллов / 100 запросов";
  const fileInputRef = useRef(null);

  const handleSuccessFinishTools = (variant) => {
    enqueueSnackbar("Получение частотности запросов завершено.", { variant });
  };

  const handleClear = () => {
    handleClickMassClear();
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const handleChangeQuery = (event) => {
    const text = event.target.value;
    const words = text.split("\n");
    setQueryArray(words);
    console.log("handleChangeMass:", words);
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = (e) => {
      const text = e.target.result;
      const words = text.split("\n").filter((word) => word.trim() !== "");
      setQueryArray(words);
    };
    reader.readAsText(file);
  };

  const handleClickMassClear = () => {
    setQueryArray([]);
    setResult([]);
  };

  const handleCitySelect = (selectedOption) => {
    setSelectedCity(selectedOption.value);
  };

  const handleFetchKey = async () => {
    try {
      setIsLoading(true);

      if (totalLvt < sumKeyLvt) {
        console.log("Баланс равен 0");
        setIsLoading(false);
        setShowModal(true);
        return;
      }

      const response = await getCountWord(queryArray, region, userId);
      debugger;
      // Проверяем, что response существует и является массивом
      if (Array.isArray(response)) {
        debugger;
        setResult(response);

        // Заменяем null на объект с фразой "Ошибка" и shows: 0
        const data = response.map((item) => {
          if (item === null) {
            return { phrase: "Ошибка", shows: 0 }; // Заменяем null
          }
          return item; // Оставляем остальные элементы без изменений
        });

        // Преобразуем данные в массив массивов для XLSX
        const formattedData = data.map((item) => [item.phrase, item.shows]);

        const ws = XLSX.utils.aoa_to_sheet(formattedData);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Результаты");

        const wbout = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const blob = new Blob([wbout], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const url = URL.createObjectURL(blob);
        setCsvDownloadLink(url);

        const formData = new FormData();
        formData.append("toolFile", blob, "result.xlsx");

        await loadFileUserTools(formData, userId, "wordstat-count-key");
      } else {
        console.error("Ошибка: response не является массивом", response);
      }
    } catch (error) {
      console.error("Ошибка при выполнении запроса:", error);
    } finally {
      await spendLvt(userId, lvtUserSpend);
      setQueryArray([]);
      getDirectBall();
      setIsLoading(false);
      handleSuccessFinishTools("success");
    }
  };

  useEffect(() => {
    getDirectBall();
  }, [getDirectBall]);

  useEffect(() => {
    const filteredQueryArray = queryArray.filter((word) => word.trim() !== "");
    if (filteredQueryArray.length > 0) {
      if (filteredQueryArray.length > ballDirect) {
        setExceedsBallDirect(true);
      } else {
        setExceedsBallDirect(false);
      }
      const sumLvtToolsKey = tarif * filteredQueryArray.length;
      setLvtUserSpend(sumLvtToolsKey.toFixed(1));

      if (totalLvt < sumLvtToolsKey) {
        setShowModal(true);
        setNoLvtUserFetching(true);
        const sumLvt = Math.abs(totalLvt - sumLvtToolsKey);
        setSumLvt(sumLvt.toFixed(1));
      } else {
        setShowModal(false);
        setNoLvtUserFetching(false);
        setSumLvt(0);
      }
      setSumKeyLvt(sumLvtToolsKey.toFixed(1));
      console.log("sumKeyLvt:", sumLvtToolsKey.toFixed(1));
    } else {
      setLvtUserSpend(0);
      setSumLvt(0);
      setSumKeyLvt(0);
      setShowModal(false);
    }
  }, [queryArray, totalLvt, ballDirect]);

  return (
    <>
      <TitleComponent
        description="Проверьте частотность запроса из сервиса Wordstat. Массовая проверка онлайн поисковых слов на частоту по статистике."
        title="Частотность запросов проверить онлайн в Wordstat статистику поисковых слов посмотреть"
      />
      <CountWordKey
        handleChangeQuery={handleChangeQuery}
        queryArray={queryArray}
        getCountWord={getCountWord}
        isAuthenticated={isAuthenticated}
        handleFetchKey={handleFetchKey}
        toolsSidebar={toolsSidebar}
        result={result}
        showModal={showModal}
        isLoading={isLoading}
        frontTarif={frontTarif}
        tarif={tarif}
        handleFileChange={handleFileChange}
        csvDownloadLink={csvDownloadLink}
        tools={tools}
        handleClickMassClear={handleClickMassClear}
        handleClear={handleClear}
        fileInputRef={fileInputRef}
        sumLvt={sumLvt}
        lvtUserSpend={lvtUserSpend}
        setShowModal={setShowModal}
        noLvtUser={noLvtUser}
        handleCitySelect={handleCitySelect}
        region={region}
        ballDirect={ballDirect}
        exceedsBallDirect={exceedsBallDirect}
        isLargeScreen={isLargeScreen}
      />
    </>
  );
}

const mapStateToProps = (state) => ({
  userId: state.user.dataUser.userId,
  isAuthenticated: state.user.isAuthenticated,
  lvt: state.user.dataUser.lvt,
  totalLvt: state.user.dataUser.totalLvt,
  tools: state.user.dataUser.tools,
  ballDirect: state.toolsData.countWordKey.ballDirect,
});

const mapDispatchToProps = {
  getCountWord,
  spendLvt,
  loadFileUserTools,
  getDirectBall,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CountWordKeyContainer);
