import { Box, Button, IconButton, Tooltip, Typography } from "@mui/material";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";

export default function MessageNoBall(props) {
  const { ball, handleClose } = props;
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignContent: "center",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <Box
        p={2}
        sx={{ backgroundColor: "#fff", width: 400, borderRadius: "10px" }}
      >
        <Box textAlign="right">
          <Tooltip title="Закрыть">
            <span>
              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </span>
          </Tooltip>
        </Box>
        <Typography mt={2} align="center">
          Для выполнения операции не хватает
          <br /> {ball} баллов
        </Typography>
        <Box mt={2} align="center">
          <Button variant="outlined">Купить баллы</Button>
        </Box>
      </Box>
    </Box>
  );
}
