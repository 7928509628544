import React from "react";
import Avatar from "@mui/material/Avatar";
import Tooltip from "@mui/material/Tooltip";
import { blueGrey } from "@mui/material/colors";
import IconButton from "@mui/material/IconButton";
import { AccountCircle } from "@mui/icons-material";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import { Typography } from "@mui/material";
import { Link } from "react-router-dom";

export default function AvatarUser(props) {
  const {
    handleOpenUserMenu,
    firstName,
    lastName,
    avatar,
    vkAvatar,
    config,
    handleLogout,
    handleCloseUserMenu,
    settings,
    anchorElUser,
    colorNullAvatar,
  } = props;
  return (
    <>
      <Tooltip title="Управление профилем">
        <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
          {avatar ? (
            <Avatar
              alt={`${firstName} ${lastName}`}
              src={
                avatar.includes(vkAvatar)
                  ? `${avatar}`
                  : `${config.REACT_APP_SERVER_URL}${avatar}`
              }
              sx={{ bgcolor: blueGrey[50] }}
            />
          ) : (
            <AccountCircle
              sx={{ color: colorNullAvatar ? colorNullAvatar : "#fff" }}
              fontSize="large"
            />
          )}
        </IconButton>
      </Tooltip>
      <Menu
        disableScrollLock={true}
        sx={{ mt: "45px" }}
        id="menu-appbar"
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
      >
        {settings.map((setting, index) => (
          <MenuItem key={index} onClick={handleCloseUserMenu}>
            <Link to={setting.link}>
              <Typography textAlign="center">{setting.name}</Typography>
            </Link>
          </MenuItem>
        ))}
        <MenuItem onClick={handleCloseUserMenu}>
          <Typography onClick={handleLogout} textAlign="center">
            Выйти
          </Typography>
        </MenuItem>
      </Menu>
    </>
  );
}
