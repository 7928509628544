import React, { useEffect } from "react";
import PostAddIcon from "@mui/icons-material/PostAdd";
import MessageNoAuth from "../../Auth/MessageNoAuth/MessageNoAuth";
import DeleteIcon from "@mui/icons-material/Delete";
import { Box, Button, CircularProgress, TextareaAutosize } from "@mui/material";
import Grid from "@mui/material/Grid2";

export default function CountWordKeyForm(props) {
  const {
    queryArray,
    handleChangeQuery,
    fileInputRef,
    handleFileChange,
    handleFetchKey,
    handleClear,
    isAuthenticated,
    isLoading,
  } = props;

  useEffect(() => {
    if (queryArray && queryArray.length > 0 && queryArray[0].trim() === "") {
      handleClear();
    }
  }, [queryArray, handleClear]);

  // Функция для очистки текста от нежелательных символов и двойных пробелов
  const cleanText = (text) => {
    return text
      .split("\n")
      .map((line) =>
        line
          .replace(/[!+-]/g, "") // Удаление символов !, +, -
          .replace(/\s{2,}/g, " ") // Замена двойных пробелов на одинарный
          .trim()
      )
      .filter((line) => line.length > 0) // Удаление пустых строк
      .join("\n");
  };

  // Модифицируем функцию handleChangeQuery для использования cleanText
  const handleCleanedChangeQuery = (event) => {
    const cleanedText = cleanText(event.target.value);
    handleChangeQuery({
      target: {
        value: cleanedText,
      },
    });
  };

  return (
    <>
      <Box
        color="#fff"
        sx={{ fontSize: "18px" }}
        component="label"
        htmlFor="key-get"
      >
        Каждый запрос с новой строки
      </Box>
      <TextareaAutosize
        placeholder="Добавьте ключевые запросы..."
        name="key-get"
        id="key-get"
        value={queryArray.join("\n")}
        minRows="7"
        maxRows="15"
        onChange={handleCleanedChangeQuery}
        ref={fileInputRef}
        style={{
          borderRadius: "8px",
          backgroundColor: "#ffffff",
          width: "100%",
          fontSize: "18px",
          maxWidth: "100%",
          minWidth: "100%",
          padding: "10px",
        }}
      />
      <Grid alignItems="stretch" mb={2} spacing={1} container>
        <Grid>
          <Button
            size="small"
            disabled={!queryArray.length > 0 || !isAuthenticated || isLoading}
            variant="contained"
            sx={{ backgroundColor: "#4CAF50", minWidth: "194px" }}
            onClick={handleFetchKey}
          >
            {isLoading ? (
              <CircularProgress size={20} sx={{ color: "#fff" }} />
            ) : (
              "Запустить проверку"
            )}
          </Button>
        </Grid>
        <Grid>
          <Button
            size="small"
            disabled={!queryArray.length > 0}
            startIcon={<DeleteIcon />}
            onClick={handleClear}
            variant="contained"
            color="error"
          >
            Очистить
          </Button>
        </Grid>
        <Grid>
          <Button
            size="small"
            disabled={queryArray.length > 0}
            startIcon={<PostAddIcon />}
            variant="contained"
            component="label"
          >
            Загрузить файл .txt
            <input
              type="file"
              name="keyFile"
              id="input__file"
              accept=".txt"
              onChange={handleFileChange}
              hidden
              ref={fileInputRef}
            />
          </Button>
        </Grid>
      </Grid>
      {!isAuthenticated ? <MessageNoAuth /> : null}
    </>
  );
}
