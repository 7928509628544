import React, { useState } from "react";
import { connect } from "react-redux";
import { logoutUserThunkCreator } from "../../redux/user-reducer/user-reducer";
import AvatarUser from "./AvatarUser";

function AvatarUserContainer(props) {
  const { colorNullAvatar } = props;
  let config;

  if (process.env.NODE_ENV === "development") {
    config = require("../../config.dev");
  } else {
    config = require("../../config.prod");
  }
  // Функция для выхода из аккаунта
  const handleLogout = () => {
    // Здесь можете добавить логику для выхода из аккаунта
    // Например, вызов logoutUserThunkCreator
    props.logoutUserThunkCreator();
    // После успешного выхода из аккаунта устанавливаем isAuthenticated в false
  };

  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const settings = [
    { name: "Профиль", link: "/profile/" },
    { name: "Рефералы", link: "/referal/" },
    { name: "История", link: "/history-message/" },
  ];

  const vkAvatar = "userapi";

  return (
    <>
      <AvatarUser
        avatar={props.avatar}
        firstName={props.firstName}
        lastName={props.lastName}
        vkAvatar={vkAvatar}
        handleLogout={handleLogout}
        settings={settings}
        anchorElUser={anchorElUser}
        handleOpenUserMenu={handleOpenUserMenu}
        handleCloseUserMenu={handleCloseUserMenu}
        handleOpenNavMenu={handleOpenNavMenu}
        anchorElNav={anchorElNav}
        handleCloseNavMenu={handleCloseNavMenu}
        config={config}
        colorNullAvatar={colorNullAvatar}
      />
    </>
  );
}

let mapStateToProps = (state) => {
  return {
    avatar: state.user.dataUser.avatar,
    firstName: state.user.dataUser.firstName,
    lastName: state.user.dataUser.lastName,
  };
};

const mapDispatchToProps = {
  logoutUserThunkCreator,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AvatarUserContainer);
