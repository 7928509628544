import { Container } from "@mui/material";
import Grid from "@mui/material/Grid2";
import React from "react";
import s from "../css/Tools.module.css";

export default function TextBottom(props) {
  return (
    <Container>
      <Grid mt={2} container>
        <Grid p={{ xs: 2, md: 6 }} size={12}>
          {props.text}
        </Grid>
      </Grid>
    </Container>
  );
}
