import React from "react";
import { Box, Divider, Paper, Typography } from "@mui/material"; // Или из другой библиотеки
import { DataGrid } from "@mui/x-data-grid";
import { ruRU } from "@mui/x-data-grid/locales";
import { format } from "date-fns";
import DiagramDataIks from "./DiagramDataIks";

export default function TableIksSite(props) {
  const { preDataIks } = props;

  // Функция для проверки корректности даты
  const isValidDate = (value) => {
    const date = new Date(value);
    return !isNaN(date.getTime());
  };

  // Формирование строк для таблицы
  const rows =
    preDataIks && preDataIks.latestDocuments
      ? preDataIks.latestDocuments.map((data, index) => {
          const formattedDate = isValidDate(data.lastCheck?.date) // Проверяем корректность даты
            ? format(new Date(data.lastCheck.date), "dd-MM-yyyy / HH:mm:ss")
            : "Недопустимая дата";

          return {
            id: index + 1,
            domen: data.domen || "Не указан",
            date: formattedDate,
            iks: data.lastCheck?.iksValue || 0,
          };
        })
      : [];

  const columns = [
    { field: "id", headerName: "ID", flex: 1 }, // flex: 1 для адаптивности
    { field: "domen", headerName: "Домен", flex: 2 }, // flex: 2 для большего пространства
    { field: "iks", headerName: "ИКС", flex: 1 },
    { field: "date", headerName: "Дата проверки", flex: 2 },
  ];

  return (
    <Box component="section" mt={4}>
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <Box p={4} sx={{ width: "100%" }}>
          <Box mt={6} mb={6}>
            <Typography
              style={{ textAlign: "center" }}
              variant="h4"
              gutterBottom
              component="h2"
            >
              ИКС других сайтов
            </Typography>
            <Typography gutterBottom component="p">
              Вы имеете возможность мониторить значение ИКС не только для вашего
              ресурса, но также для сайтов ваших конкурентов и топовых проектов
              в поисковой выдаче.
            </Typography>
          </Box>
          <Divider />
          <Box mt={6} mb={6}>
            <Typography
              gutterBottom
              component="h3"
              variant="h5"
              style={{ textAlign: "center" }}
            >
              Распределение ИКС
            </Typography>
            <Typography gutterBottom component="p">
              На данный момент в нашей базе данных содержится{" "}
              <span style={{ color: "#1976d3", fontWeight: "600" }}>
                {preDataIks?.totalDocuments || 0}
              </span>{" "}
              доменов.
            </Typography>
            <Typography sx={{ color: "#a1a1a1" }} gutterBottom component="p">
              Последнее обновление базы произведено{" "}
              {isValidDate(preDataIks?.updatedAt) // Проверяем корректность updatedAt
                ? format(
                    new Date(preDataIks.updatedAt),
                    "dd-MM-yyyy / HH:mm:ss"
                  )
                : "Недопустимая дата"}
              .
            </Typography>
          </Box>
          <Box mt={6} mb={6}>
            <DiagramDataIks diagramDataIks={preDataIks.iksDistribution} />
          </Box>
          <Divider />
          <Box mt={6} mb={6}>
            <Typography align="center" variant="h5" gutterBottom component="h3">
              База проверенных ИКС сайтов
            </Typography>
            <DataGrid
              mt={2}
              mb={2}
              sx={{
                border: 0,
                "& .odd": {
                  backgroundColor: "#f5f5f5",
                },
                "& .even": {
                  backgroundColor: "#ffffff",
                },
              }}
              rows={rows}
              columns={columns}
              localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
              getRowClassName={(params) =>
                params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
              }
            />
          </Box>
        </Box>
      </Paper>
    </Box>
  );
}
