import React, { useState } from "react";
import CountWordKeyForm from "./parts/CountWordKeyForm";
import CountWordKeyResult from "./parts/CountWordKeyResult";
import CountWordKeyTarif from "./parts/CountWordKeyTarif";
import HistoryToolsUser from "../ToolsComponent/PartsComponentTools/HistoryToolsUser";
import RegionSelectSearch from "../ToolsComponent/PartsComponentTools/RegionSelectSearch";
import { Box, Button, LinearProgress, Modal, Typography } from "@mui/material";
import MessageNoBall from "../app-function/MessageNoBall";

export default function CountWordKey(props) {
  const {
    handleChangeQuery,
    queryArray,
    handleFetchKey,
    lvtUserSpend,
    massKey,
    result,
    csvDownloadLink,
    handleClickMassClear,
    isLoading,
    frontTarif,
    handleFileChange,
    tools,
    fileInputRef,
    handleClear,
    isAuthenticated,
    noLvtUser,
    handleCitySelect,
    region,
    ballDirect,
    exceedsBallDirect,
    isLargeScreen,
    showModal,
  } = props;

  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = (userId) => {
    setOpen(true);
  };

  return (
    <>
      <Box
        component="section"
        p={{ xs: 1, md: 12 }}
        pt={{ xs: 10 }}
        sx={{
          background: "#1976d3",
          position: "relative",
          minHeight: props.isAuthenticated ? "94vh" : "64vh",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            position: "absolute",
            top: "30px",
            left: "97px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Typography
              mr={1}
              sx={{ fontWeight: "600", fontSize: { xs: "16px", md: "25px" } }}
              color="#fff"
              component="span"
            >
              Яндекс
            </Typography>
            <Typography
              sx={{ fontWeight: "600", fontSize: { xs: "16px", md: "25px" } }}
              color="#fff"
              component="span"
            >
              Вордстат
            </Typography>
          </Box>
        </Box>
        <>
          <Typography
            color="#fff"
            variant={isLargeScreen ? "h3" : "h4"}
            component="h1"
            textAlign="center"
            gutterBottom
          >
            Проверить частотность запросов
          </Typography>
          <Box>
            <Box mt={3} mb={3} sx={{ width: "200px" }} component="div">
              <Box sx={{ fontSize: "15px", color: "#fff" }} component="label">
                Выбор региональности
              </Box>
              <RegionSelectSearch
                defaultRegion={region}
                onSelect={handleCitySelect}
                nameLabel="Регион проверки:"
              />
            </Box>
            <CountWordKeyTarif
              massKey={massKey}
              queryArray={queryArray}
              lvtUserSpend={lvtUserSpend}
              frontTarif={frontTarif}
              ballDirect={ballDirect}
              isLargeScreen={isLargeScreen}
            />
            <CountWordKeyForm
              queryArray={queryArray}
              handleChangeQuery={handleChangeQuery}
              result={result}
              fileInputRef={fileInputRef}
              handleFileChange={handleFileChange}
              handleClickMassClear={handleClickMassClear}
              handleFetchKey={handleFetchKey}
              handleClear={handleClear}
              isAuthenticated={isAuthenticated}
              noLvtUser={noLvtUser}
              exceedsBallDirect={exceedsBallDirect}
              isLoading={isLoading}
            />
          </Box>
          <CountWordKeyResult
            result={result}
            csvDownloadLink={csvDownloadLink}
            lvtUserSpend={lvtUserSpend}
            queryArray={queryArray}
            massKey={massKey}
            handleClear={handleClear}
          />
        </>
        {isLoading && (
          <Box>
            <LinearProgress />
          </Box>
        )}
        <HistoryToolsUser
          tools={tools}
          nameTools="wordstat-count-key"
          titleTools="История результатов проверки частотностей"
        />
      </Box>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title-2"
        aria-describedby="modal-modal-description-2"
        sx={{ backgroundColor: "#00000012" }}
      >
        <span>
          <MessageNoBall handleClose={handleClose} ball="200" />
        </span>
      </Modal>
      <Button onClick={handleOpen}>Открыть</Button>
    </>
  );
}
