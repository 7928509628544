import React from "react";
import {
  Box,
  Button,
  CircularProgress,
  LinearProgress,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import TextAreaIks from "./Parts/TextAreaIks";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import SiteResultTable from "./Parts/SiteResultTable";
import YaIksTarif from "./Parts/YaIksTarif";
import CheckedNullIks from "./Parts/CheckedNullIks";
import TableIksSite from "./Parts/TableIksSite";

export default function YaIks(props) {
  const {
    handleChange,
    siteArray,
    siteResult,
    handleClick,
    handleClear,
    isLoading,
    handleChangeChek,
    handleChangeNumber,
    stateChek,
    stateNumberIks,
    handleRegistrationDomen,
    preDataIks,
    text,
  } = props;

  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("lg"));

  return (
    <Box>
      <Box
        component="section"
        p={{ xs: 1, md: 12 }}
        pt={{ xs: 10 }}
        sx={{
          background: "#f9f9f9",
          position: "relative",
          minHeight: props.isAuthenticated ? "94vh" : "94vh",
        }}
      >
        <Box>
          <Typography
            color="#000"
            variant={isLargeScreen ? "h4" : "h5"}
            component="h1"
            textAlign="center"
            gutterBottom
          >
            Массовая проверка Яндекс ИКС сайта
          </Typography>
        </Box>
        <Box
          p={5}
          sx={{
            borderRadius: "5px",
            border: "2px solid #fff",
            background: "#fff",
          }}
        >
          <Box>
            <YaIksTarif isLargeScreen={isLargeScreen} siteArray={siteArray} />
            <TextAreaIks
              handleClear={handleClear}
              siteArray={siteArray}
              handleChange={handleChange}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              gap: "30px",
              justifyContent: "space-between",
              alignItems: "flex-start",
            }}
          >
            <Box>
              <Button
                disabled={!siteArray.length || isLoading} // Проверка на пустоту массива
                startIcon={!isLoading && <PlayCircleOutlineIcon />}
                variant="contained"
                sx={{ backgroundColor: "#4CAF50", minWidth: "200px" }}
                onClick={handleClick}
              >
                {isLoading ? (
                  <CircularProgress size={20} sx={{ color: "#fff" }} />
                ) : (
                  "Собрать данные"
                )}
              </Button>
            </Box>
            <Box>
              <CheckedNullIks
                handleChangeChek={handleChangeChek}
                handleChangeNumber={handleChangeNumber}
                stateChek={stateChek}
                stateNumberIks={stateNumberIks}
              />
            </Box>
          </Box>
        </Box>
        {isLoading && (
          <Box>
            <LinearProgress />
          </Box>
        )}
        {siteResult.length > 0 && (
          <Box mt={4}>
            <SiteResultTable
              siteResult={siteResult}
              stateChek={stateChek}
              stateNumberIks={stateNumberIks}
              handleRegistrationDomen={handleRegistrationDomen}
            />
          </Box>
        )}
        <Box sx={{ backgroundColor: "#fff", borderRadius: "5px" }}>{text}</Box>
        <Box>
          <TableIksSite preDataIks={preDataIks} />
        </Box>
      </Box>
    </Box>
  );
}
