import { Box, Button, Typography } from "@mui/material";
import React from "react";

export default function DashboardPanelUser(props) {
  const { email, handleLogout } = props;
  return (
    <Box
      sx={{
        minHeight: "200px",
        padding: "15px",
        borderRadius: "10px",
        background:
          "linear-gradient(90deg, rgba(25,118,211,1) 0%, rgba(25,176,211,1) 100%)",
      }}
    >
      <Typography color="#fff" gutterBottom variant="h6" component="div">
        Аккаунт
      </Typography>
      <Typography color="#fff" gutterBottom variant="h6" component="div">
        {email}
      </Typography>
      <Box sx={{ display: "flex", gap: "20px" }}>
        <Button
          sx={{ marginTop: "30px", backgroundColor: "#e7e7e7", color: "#000" }}
          variant="contained"
          component="a"
          href="/profile/"
        >
          В настройки
        </Button>
        <Button
          sx={{ marginTop: "30px", backgroundColor: "#4CAF50" }}
          variant="contained"
          onClick={handleLogout}
        >
          Выйти
        </Button>
      </Box>
    </Box>
  );
}
