import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import NotificationContainer from "./Notification/NotificationContainer";
import { Chip, Divider, Stack } from "@mui/material";
import Grid from "@mui/material/Grid2";
import CloudIcon from "@mui/icons-material/Cloud";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import DashboardIcon from "@mui/icons-material/Dashboard";
import AvatarUserContainer from "./Avatar/AvatarUserContainer";

function Header(props) {
  const {
    avatar,
    firstName,
    lastName,
    money,
    totalLvt,
    handleLogout,
    settings,
    anchorElUser,
    handleOpenUserMenu,
    handleCloseUserMenu,
  } = props;

  let config;

  if (process.env.NODE_ENV === "development") {
    config = require("../config.dev");
  } else {
    config = require("../config.prod");
  }
  return (
    <Container
      sx={{
        background:
          "linear-gradient(90deg, rgba(25, 118, 211, 1) 0%, rgba(25, 176, 211, 1) 100%)",
        marginBottom: "20px",
      }}
      maxWidth={false}
    >
      <Grid
        sx={{ height: { xs: "100%", md: "75px" } }}
        alignItems="center"
        container
        spacing={2}
      >
        <Grid
          justifyContent="center"
          display="flex"
          pt={{ xs: 1, md: 0 }}
          size={{ xs: 12, md: "auto" }}
        >
          <Link style={{ display: "flex", alignItems: "center" }} to="/">
            <Box
              sx={{ mr: 1 }}
              component="img"
              src="/img/logo/white-logo-ptahini.png"
              alt="Логотип Ptahini"
              style={{ width: "50px" }}
            />
            <Typography
              variant="h6"
              noWrap
              component="p"
              sx={{
                mr: 2,
                fontFamily: "monospace",
                fontWeight: 700,
                letterSpacing: ".3rem",
                color: "#fff",
                textDecoration: "none",
              }}
            >
              PTAHINI
            </Typography>
          </Link>
        </Grid>
        <Grid size="auto">
          <Button
            href="/dashboard/"
            sx={{ backgroundColor: "#fff" }}
            variant="outlined"
            startIcon={<DashboardIcon />}
          >
            Панель инструментов
          </Button>
        </Grid>
        <Grid
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            gap: "15px",
          }}
          size="grow"
        >
          <Box sx={{ display: { xs: "none", md: "contents" } }}>
            <Divider orientation="vertical" flexItem />
            <Stack direction="row" spacing={1}>
              <Chip
                sx={{
                  backgroundColor: "#4CAF50", // Используем кастомный цвет
                  color: "#fff", // Добавим цвет текста, если нужно
                  fontWeight: "600",
                }}
                icon={<CreditCardIcon color="#fff" size="small" />}
                label={`${money} руб`}
              />
            </Stack>
            <Divider orientation="vertical" flexItem />
            <Stack direction="row" spacing={1}>
              <Chip
                sx={{
                  backgroundColor: "#4CAF50", // Используем кастомный цвет
                  color: "#fff", // Добавим цвет текста, если нужно
                  fontWeight: "600",
                }}
                icon={<CloudIcon color="#fff" size="small" />}
                label={`${totalLvt} баллов`}
              />
            </Stack>
            <Divider orientation="vertical" flexItem />
          </Box>
          <NotificationContainer />
          <Divider orientation="vertical" flexItem />
          <AvatarUserContainer />
        </Grid>
      </Grid>
    </Container>
  );
}
export default Header;
