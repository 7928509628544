import React from "react";
import { Box, Button, Typography, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import Grid from "@mui/material/Grid2";
import DomenDeleteResultTable from "./Parts/DomenDeleteResultTable";
import BlockDomenVariant from "./Parts/BlockDomenVariant";
// import SiteResultTable from "./Parts/SiteResultTable";

export default function DomenDelete(props) {
  const { handleClick, domenResult, domenResultData } = props;

  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("lg"));

  return (
    <Box>
      <Box
        component="section"
        p={{ xs: 1, md: 12 }}
        pt={{ xs: 10 }}
        sx={{
          background: "#1976d3",
          position: "relative",
          minHeight: props.isAuthenticated ? "94vh" : "94vh",
        }}
      >
        <Box>
          <Typography
            color="#fff"
            variant={isLargeScreen ? "h3" : "h4"}
            component="h1"
            textAlign="center"
            gutterBottom
          >
            Список освобождающихся доменных имен
          </Typography>
        </Box>
        <Grid container spacing={3} mt={3}>
          <Grid size={{ xs: 12, md: 6, lg: 4 }}>
            <BlockDomenVariant
              functionClick={handleClick}
              name="Домены .RU"
              zonaDomen="RU"
              description="Доменные имена освобождаются каждый день кроме: выходных, праздников и следующего дня после выходного. Списки обновляются в 12:00 по МСК. Регистрация доменов начинается в 17:00 МСК"
              textButton="Получить список"
            />
          </Grid>
          <Grid size={{ xs: 12, md: 6, lg: 4 }}>
            <BlockDomenVariant
              functionClick={handleClick}
              zonaDomen="RF"
              name="Домены .РФ"
              description="Доменные имена освобождаются каждый день кроме: выходных, праздников и следующего дня после выходного. Списки обновляются в 12:00 по МСК. Регистрация доменов начинается в 17:00 МСК"
              textButton="Получить список"
            />
          </Grid>
        </Grid>
        {domenResult.length > 0 && (
          <Box>
            <DomenDeleteResultTable
              domenResultData={domenResultData}
              domenResult={domenResult}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
}
