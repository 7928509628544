import React from "react";
import DashboardPanel from "./DashboardPanel";
import { connect } from "react-redux";
import { logoutUserThunkCreator } from "../../redux/user-reducer/user-reducer";

function DashboardPanelContainer(props) {
  const {
    userId,
    tools,
    isAuthenticated,
    money,
    totalLvt,
    email,
    logoutUserThunkCreator,
    currentTariff,
  } = props;

  const handleLogout = () => {
    logoutUserThunkCreator();
  };

  return (
    <DashboardPanel
      totalLvt={totalLvt}
      money={money}
      isAuthenticated={isAuthenticated}
      tools={tools}
      userId={userId}
      email={email}
      currentTariff={currentTariff}
      handleLogout={handleLogout}
    />
  );
}

let mapStateToProps = (state) => {
  return {
    userId: state.user.dataUser.userId,
    email: state.user.dataUser.email,
    currentTariff: state.user.dataUser.currentTariff,
    isAuthenticated: state.user.isAuthenticated,
    tools: state.user.dataUser.tools,
    money: state.user.dataUser.money,
    totalLvt: state.user.dataUser.totalLvt,
  };
};

const mapDispatchToProps = {
  logoutUserThunkCreator,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DashboardPanelContainer);
