import React, { useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import { ruRU } from "@mui/x-data-grid/locales";
import { Typography, Box } from "@mui/material";
import decodePunycode from "../../Whois/Parts/PunycodeConverter";

// Функция для декодирования Punycode
// const decodePunycode = (domain) => {
//   try {
//     if (domain.startsWith("xn--")) {
//       return new URL(`http://${domain}`).hostname;
//     }
//     return domain;
//   } catch (error) {
//     console.error(`Ошибка декодирования домена: ${domain}`, error);
//     return domain; // Возврат оригинального домена в случае ошибки
//   }
// };

export default function DomenDeleteResultTable(props) {
  const { domenResult, domenResultData } = props;
  const [selectedRows, setSelectedRows] = useState([]);

  const handleSelectionChange = (newSelection) => {
    setSelectedRows(newSelection);
  };

  // Пользовательская функция сортировки по количеству символов
  const sortByLength = (v1, v2) => {
    const length1 = v1.length;
    const length2 = v2.length;
    return length1 - length2;
  };

  // Универсальная функция для скачивания
  const handleDownload = (domains, fileName = "domains.txt") => {
    const decodedDomains = domains.map(decodePunycode); // Преобразование Punycode
    const blob = new Blob([decodedDomains.join("\n")], { type: "text/plain" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const handleDownloadSelected = () => {
    const selectedDomains = selectedRows.map(
      (id) => domenResult.find((row) => row.id === id).domains
    );
    handleDownload(selectedDomains, "selected_domains.txt");
  };

  const handleDownloadAll = () => {
    const allDomains = domenResult.map((row) => row.domains);
    handleDownload(allDomains, "all_domains.txt");
  };

  const paginationModel = { page: 0, pageSize: 100 };

  // Преобразование данных для отображения в таблице
  const rows = domenResult.map((row) => ({
    ...row,
    domains: decodePunycode(row.domains), // Декодирование Punycode для отображения
  }));

  const columns = [
    { field: "id", headerName: "ID", width: 70 },
    {
      field: "domains",
      headerName: "Домен",
      width: 200,
      sortComparator: sortByLength,
    },
  ];

  return (
    <>
      <Typography color="#fff" mt={4} gutterBottom variant="h6" component="h4">
        {domenResultData}
      </Typography>
      <Paper sx={{ width: "100%", padding: "15px" }}>
        <Box sx={{ display: "flex", gap: "15px" }}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleDownloadAll}
          >
            Скачать все домены
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleDownloadSelected}
            disabled={selectedRows.length === 0}
          >
            Скачать выбранные домены
          </Button>
        </Box>
        <DataGrid
          rows={rows} // Используем преобразованные данные
          columns={columns}
          initialState={{ pagination: { paginationModel } }}
          pageSizeOptions={[10, 25, 50, 100]}
          checkboxSelection
          onRowSelectionModelChange={handleSelectionChange}
          sx={{ border: 0 }}
          localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
        />
      </Paper>
    </>
  );
}
