import React from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarController,
  BarElement,
  Tooltip,
  Legend,
} from "chart.js";
import { Box, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarController,
  BarElement,
  Tooltip,
  Legend
);

const fallbackData = {
  labels: ["Нет данных"],
  datasets: [
    {
      data: [100],
      backgroundColor: ["#E7E9ED"],
      hoverBackgroundColor: ["#E7E9ED"],
    },
  ],
};

export default function DiagramDataIks(props) {
  const { diagramDataIks } = props;

  // Функция для сортировки категорий
  const sortCategories = (data) => {
    return data.sort((a, b) => {
      const categoryA = getCategoryRange(a.category);
      const categoryB = getCategoryRange(b.category);
      return categoryA - categoryB;
    });
  };

  // Функция для получения числового диапазона из категории
  const getCategoryRange = (category) => {
    if (category === "0") {
      return -Infinity; // Категория "0" должна быть первой
    }
    const match = category.match(/(\d+)-(\d+)/);
    if (match) {
      return parseInt(match[1]); // Берем нижнюю границу диапазона
    }
    if (category === "1000+") {
      return 1000; // Обрабатываем случай "1000+"
    }
    return Infinity; // Если категория не распознана, помещаем её в конец
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: true,
      },
    },
    hover: {
      mode: "nearest",
      intersect: true,
    },
    animation: {
      duration: 1000,
      easing: "easeInOutBounce",
    },
    scales: {
      x: {
        beginAtZero: true,
      },
      y: {
        beginAtZero: true,
      },
    },
  };

  // Сортируем данные перед использованием
  const sortedData =
    diagramDataIks && Array.isArray(diagramDataIks)
      ? sortCategories(diagramDataIks)
      : [];

  // Если данных нет, используем заглушку
  const chartData =
    sortedData.length > 0
      ? {
          labels: sortedData.map((item) => `ИКС ${item.category}`),
          datasets: [
            {
              label: "Распределение доменов по ИКС",
              data: sortedData.map((item) => item.count),
              backgroundColor: [
                "#FF6384",
                "#36A2EB",
                "#FFCE56",
                "#4BC0C0",
                "#fbac11",
                "#9966FF",
              ],
              hoverBackgroundColor: [
                "#FF6384",
                "#36A2EB",
                "#FFCE56",
                "#4BC0C0",
                "#fbac11",
                "#9966FF",
              ],
            },
          ],
        }
      : fallbackData;

  return (
    <Box>
      <Grid sx={{ alignItems: "center" }} spacing={4} container>
        <Grid size={{ md: 8, xs: 12 }}>
          <Bar options={options} data={chartData} />
        </Grid>
        <Grid
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
          size={{ md: 4, xs: 12 }}
        >
          <Typography align="center" variant="h5" component="h4">
            Значения
          </Typography>
          {sortedData.length > 0 ? (
            <ul style={{ listStyleType: "none" }}>
              {sortedData.map((item, index) => (
                <li key={index}>
                  {`ИКС ${item.category}: ${item.count} (${item.percentage.toFixed(2)}%)`}
                </li>
              ))}
            </ul>
          ) : (
            <p>Данные пока недоступны.</p>
          )}
        </Grid>
      </Grid>
    </Box>
  );
}
