import * as React from "react";
import Box from "@mui/material/Box";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { AppProvider } from "@toolpad/core/AppProvider";
import { DashboardLayout } from "@toolpad/core/DashboardLayout";
import { useDemoRouter } from "@toolpad/core/internal";
import ToolsContentContainer from "../ToolsComponent/ToolsContentContainer";
import PageContainer from "../Page/PageContainer";
import HistoryIcon from "@mui/icons-material/History";
import TitleIcon from "@mui/icons-material/Title";
import LanguageIcon from "@mui/icons-material/Language";
import SignpostIcon from "@mui/icons-material/Signpost";
import VpnKeyIcon from "@mui/icons-material/VpnKey";
import AppsIcon from "@mui/icons-material/Apps";
import { Chip, createTheme, Toolbar } from "@mui/material";
import CurrencyRubleIcon from "@mui/icons-material/CurrencyRuble";
import WbCloudyIcon from "@mui/icons-material/WbCloudy";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import { ruRU } from "@mui/x-data-grid/locales";
import { TextField } from "@mui/material";
import { Search } from "@mui/icons-material";
import HeaderCabinet from "./PartsCabinet/HeaderCabinet";
import SearchField from "./PartsCabinet/SearchField";

function CabinetUser(props) {
  const { tools, money, lvt, currentTariff, role } = props;

  const NAVIGATION = [
    {
      segment: "#",
      title: "Ваш баланс",
      icon: <CurrencyRubleIcon />,
      action: (
        <Chip
          sx={{
            borderRadius: "5px",
            fontWeight: "600",
            backgroundColor: "#FFC107",
          }}
          label={`${money} руб`}
          size="small"
          title={`${money} руб`}
        />
      ),
    },
    {
      segment: "#",
      title: "Остаток баллов",
      icon: <WbCloudyIcon />,
      action: (
        <Chip
          sx={{
            borderRadius: "5px",
            fontWeight: "600",
            backgroundColor: "#FFC107",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            maxWidth: "100px",
          }}
          title={`${lvt} баллов`}
          label={`${lvt} баллов`}
          size="small"
        />
      ),
    },
    {
      segment: "balance/",
      title: "Тариф",
      icon: <ShoppingCartIcon />,
      action: (
        <Chip
          sx={{
            borderRadius: "5px",
            fontWeight: "600",
            backgroundColor: "#4CAF50",
            color: "#fff",
          }}
          label={currentTariff}
          size="small"
        />
      ),
    },
    {
      kind: "header",
      title: "Управление приложением",
    },
    {
      segment: "dashboard/",
      title: "Панель управления",
      icon: <DashboardIcon />,
    },
    {
      segment: "dashboard/history-app/",
      title: "История проверок",
      icon: <HistoryIcon />,
    },
    {
      kind: "divider",
    },
    {
      kind: "header",
      title: "Аналитика",
    },
    {
      segment: "app",
      title: "Анализ сайта",
      icon: <QueryStatsIcon />,
      children: [
        {
          segment: "yandex-iks/",
          title: "Проверка Яндекс ИКС",
          icon: <AppsIcon />,
        },
      ],
    },
    {
      kind: "divider",
    },
    {
      kind: "header",
      title: "Техническая часть",
    },
    {
      segment: "app",
      title: "Работа с мета-тегами",
      icon: <TitleIcon />,
      children: [
        {
          segment: "seo-title/",
          title: "Создание Title",
          icon: <AppsIcon />,
        },
      ],
    },
    {
      kind: "divider",
    },
    {
      kind: "header",
      title: "Ключевые запросы",
    },
    {
      segment: "app",
      title: "Анализ ключей",
      icon: <VpnKeyIcon />,
      children: [
        {
          segment: "commerce-key/",
          title: "Коммерциализация запроса",
          icon: <AppsIcon />,
        },
        {
          segment: "wordstat/",
          title: "Частотность запроса Wordstat",
          icon: <AppsIcon />,
        },
      ],
    },
    {
      kind: "divider",
    },
    {
      kind: "header",
      title: "Дополнительные инструменты",
    },
    {
      segment: "app",
      title: "Структура сайта",
      icon: <SignpostIcon />,
      children: [
        {
          segment: "mind-map/",
          title: "Построение структуры",
          icon: <AppsIcon />,
        },
      ],
    },
    {
      segment: "app",
      title: "Работа с доменами",
      icon: <LanguageIcon />,
      children: [
        {
          segment: "whois/",
          title: "Whois проверка",
          icon: <AppsIcon />,
        },
        {
          segment: "del-list/",
          title: "Освобождающиеся домены",
          icon: <AppsIcon />,
        },
      ],
    },
  ];

  const router = useDemoRouter("dashboard/");

  const theme = createTheme({
    typography: {
      fontFamily: ["Nunito Sans", "Arial", "sans-serif"].join(","),
    },
    palette: {
      success: {
        main: "#009688",
      },
      white: {
        main: "#fff",
      },
      mat: {
        main: "#21b5ae",
      },
    },
    ruRU,
  });

  return (
    <AppProvider
      branding={{
        logo: (
          <img
            src="/img/logo/logo-ptahini-digital-2.png"
            alt="Ptahini Digital"
          />
        ),
        title: "",
        homeUrl: "/dashboard/",
      }}
      navigation={NAVIGATION}
      router={router}
      theme={theme}
    >
      {/* <Toolbar
        sx={{
          position: "absolute",
          top: role === "admin" ? "42px" : "6px",
          right: "-10px",
          zIndex: "9999",
          width: "100%",
        }}
      >
        <HeaderCabinet />
      </Toolbar> */}
      <DashboardLayout sx={{ width: "100%" }}>
        <Box>
          <ToolsContentContainer pathname={router.pathname} />
          <PageContainer tools={tools} pathname={router.pathname} />
        </Box>
      </DashboardLayout>
    </AppProvider>
  );
}

export default CabinetUser;
